<template>
  <v-card
    flat
    width='450'
  >
    <v-container fluid justify='center' class='h-100'>
      <v-card-title>{{isEditing ? $t('EDIT_CUSTOMER') : $t('NEW_CUSTOMER')}}</v-card-title>
      <v-row no-gutters>
        <v-card-text class='pt-0'>
          <v-form ref='form' v-model='formIsValid' lazy-validation>
            <v-text-field
              v-model='customer.name'
              :rules='[rules.required]'
              :label='$t("NAME")'
              clearable
            />
            <v-row>
              <v-col cols='12' md='6'>
                <v-text-field
                  v-model='customer.cnpj'
                  v-mask:bind='cnpjMask'
                  :rules='[rules.validCnpj]'
                  :label='$t("CNPJ")'
                  clearable
                />
              </v-col>
              <v-col cols='12' md='6'>
                <v-text-field
                  v-model='customer.phone'
                  v-mask:bind='phoneMask'
                  type='tel'
                  :label='$t("PHONE")'
                  clearable
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols='12' md='6'>
                <v-text-field
                  v-model='customer.email'
                  type='email'
                  :rules='[rules.validEmail]'
                  :label='$t("EMAIL")'
                  clearable
                />
              </v-col>
              <v-col cols='12' md='3'>
                <v-text-field
                  v-model='customer.acronym'
                  :rules='[rules.required]'
                  :label='$t("ACRONYM")'
                />
              </v-col>
              <v-col cols='12' md='3'>
                <v-checkbox
                  v-model='customer.active'
                  label='Ativo'
                />
              </v-col>
            </v-row>

            <v-textarea
              v-model='customer.description'
              :rules='[rules.counter]'
              :label='$t("DESCRIPTION")'
              rows='3'
              maxlength='100'
              counter
              no-resize
              clearable
            />
            <v-select
              v-if='isEditing'
              v-model='selectedGroups'
              :rules='[rules.required]'
              :items='groups'
              item-text='name'
              item-value='id'
              label='Grupos'
              multiple
              chips
              hint='Selecione um ou mais grupos para o cliente'
              persistent-hint
              return-object
            />

            <v-row>
              <v-col cols='12'>
                <v-checkbox
                  v-model='customer.allowFingerprintSignin'
                  label='Habilitar login por biometria?'
                />
              </v-col>
            </v-row>
          </v-form>
          <v-btn block color='secondary' class='mt-8' @click='handleOnConfirm'>
            {{$t('CONFIRM')}}
          </v-btn>
        </v-card-text>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import axios from 'axios';
  import { mask } from 'vue-the-mask';
  import { validateCnpj } from '../../util/validation/cnpj';
  import { validateEmail } from '../../util/validation/email';

  export default {
    name: 'CustomersForm',
    directives: { mask },
    props: {
      isEditing: Boolean,
      customerId: {
        type: Number,
        default: 0,
      },
    },
    mounted: async function () {
      if (this.isEditing) {
        await this.getDataById(this.customerId);
        await this.getGroups();
        this.selectedGroups = this.customer.groups;
      }
    },
    data: function () {
      return {
        cnpjMask: '##.###.###/####-##',
        phoneMask: '(##) #####-####',
        formIsValid: false,
        showPassword: false,
        rules: {
          validCnpj: (value) => !value || (value && validateCnpj(value)) || this.$t('INVALID_CNPJ'),
          validEmail: (value) => !value || (value && validateEmail(value)) || this.$t('INVALID_EMAIL'),
          required: (value) => !!value || this.$t('REQUIRED'),
          counter: (value) => (value && value.length) <= 100 || this.$t('MAX_CHAR', { value: 100 }),
        },
        customer: {
          id: undefined,
          name: undefined,
          cnpj: undefined,
          email: undefined,
          description: undefined,
          phone: undefined,
          acronym: undefined,
          active: true,
          allowFingerprintSignin: false,
          groups: undefined,
        },
        groups: [],
        selectedGroups: [],
      };
    },
    methods: {
      togglePasswordVisibility: function () {
        this.showPassword = !this.showPassword;
      },
      handleOnConfirm: function () {
        if (!this.formIsValid) {
          this.$refs.form.validate();
          return;
        }

        if (this.isEditing) {
          this.edit();
        } else {
          this.save();
        }
      },
      getDataById: async function (id) {
        try {
          const { data } = await axios({
            url: `/customer/${id}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.customer = data && data.customer;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      getGroups: async function () {
        try {
          const { data } = await axios({
            url: '/permission-group',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.groups = data && data.list;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      save: async function () {
        try {
          const {
            name, cnpj, email, description, phone, acronym, active, allowFingerprintSignin,
          } = this.customer;
          await axios({
            url: '/customer',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              name,
              email,
              cnpj: cnpj?.replace(/\D/g, ''),
              description,
              phone: phone?.replace(/\D/g, ''),
              acronym,
              active,
              allowFingerprintSignin,
            },
          });

          this.$emit('new-customer');
          this.$emit('close');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      edit: async function () {
        try {
          const {
            id, name, cnpj, email, description, phone, acronym, active, allowFingerprintSignin,
          } = this.customer;
          await axios({
            url: `/customer/${id}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'PUT',
            responseType: 'json',
            withCredentials: true,
            data: {
              name,
              email,
              cnpj: cnpj?.replace(/\D/g, ''),
              description,
              phone: phone?.replace(/\D/g, ''),
              acronym,
              active,
              allowFingerprintSignin,
              groups: this.selectedGroups,
            },
          });

          this.$emit('edit-customer');
          this.$emit('close');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
